import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { StoreItem } from "./objects/StoreItem";
import { EnvironmentContext } from "./EnvironmentContext";
import { useContext } from "react";
import { getCost } from "./GetCost";
import { Icon } from "./Icons";

/**
 * The props for the detail page card
 */
export type DetailPageCardProps = {

    /**
     * The store item
     */
    storeItem: StoreItem;

    /**
     * Details of the store item
     */
    details: any;

    /**
     * The on click event
     * @returns 
     */
    onClick: () => void;
}

/**
 * Represents the app details card on the detail page
 * @param props - The props
 * @returns The JSX element
 */
export const DetailPageCard = (props: DetailPageCardProps) => {
    const auth = useAuth();
    const { t } = useTranslation();
    const { environment } = useContext(EnvironmentContext);

    /**
     * Check whether the store item needs an update, can be installed or is already installed.
     * @returns status of store item
     */
    const checkUpdate = (outdated: boolean | null) => {
        // Define common elements
        const updateStatus = (
            <div className="status status-update-available">
                <span className="dot"></span>{t("UPDATE_AVAILABLE")}
            </div>
        );

        const installedStatus = (
            <div className="status status-installed">
                <span className="icon-tick"></span>{t("INSTALLED")}
            </div>
        );

        const environmentError = <div className="environment-error">{t("ENTER_ENVIRONMENT")}</div>;

        // Button setup based on state
        const getButton = (isUpdate = false) => {
            const buttonText = isUpdate ? t("INSTALL_UPDATE") : t("INSTALL");
            const buttonClass = isUpdate ? "update-button" : "install-button";

            // Only show install button for sysadmins
            if (props.storeItem.visibility === "OnRequest" && !auth.user?.profile.sysadmin) {
                return null;
            }

            if (environment) {
                return (
                    <button onClick={props.onClick} className={`button ${buttonClass}`} type="button">
                        {buttonText}
                    </button>
                );
            } else {
                return (
                    <>
                        <button className={`button ${buttonClass}`} aria-disabled disabled>
                            {buttonText}
                        </button>
                        {environmentError}
                    </>
                );
            }
        };

        // Return appropriate UI based on outdated status
        if (outdated === true) {
            return (
                <>
                    {updateStatus}
                    {getButton(true)}
                </>
            );
        } else if (outdated === false) {
            return installedStatus;
        } else {
            return getButton();
        }
    };

    return <div className="card">
        <div className="card-top">
            <div className="image">
                <Icon details={props.details} data={props.storeItem} />
            </div>
            <h1>{props.storeItem.name}</h1>
            {props.storeItem.manifestType !== "Block" && <p>{props.storeItem.description}</p>}
            <div className="tag">{t(getCost(props.storeItem.visibility))}</div>
            {auth.isAuthenticated && checkUpdate(props.storeItem.outdated)}
        </div>
        <div className="card-bottom">
            <dl>
                <dd>{t('NAME', { ns: "common" })}</dd>
                <dt>{props.storeItem.name}</dt>
                <dd>{t('AUTHOR', { ns: "viewer" })}</dd>
                <dt>{props.storeItem.author}</dt>
                <dd>{t("VERSION")}</dd>
                <dt>{props.storeItem.version}</dt>
                <dd>{t("LAST_UPDATE")}</dd>
                <dt>
                    {new Date(props.storeItem.lastUpdatedAt).toLocaleDateString("nl-NL")}
                </dt>
            </dl>
        </div>
    </div>;
}